import { GoogleLogin, CredentialResponse } from '@react-oauth/google';

type Props = {
  googleSignInSuccess?: (credentialResponse: CredentialResponse) => void | null;
  asLogin?: boolean;
  useOneTap?: boolean;
  theme?: 'outline' | 'filled_blue' | 'filled_black';
};

// https://livefiredev.com/in-depth-guide-sign-in-with-google-in-a-react-js-application/

export default function SignInGoogleButton({
  googleSignInSuccess,
  asLogin,
  useOneTap,
  theme = 'filled_blue',
}: Props) {
  return (
    <GoogleLogin
      onSuccess={
        googleSignInSuccess
          ? googleSignInSuccess
          : () => {
              console.log('missing googleSignInSuccess');
            }
      }
      onError={(): void => {
        console.log('error');
      }}
      useOneTap={Boolean(useOneTap)}
      theme={theme}
      size="large"
      shape="rectangular"
      type="standard"
    />
  );
}
