// Lib
import { EnqueueSnackbar } from 'notistack';
// Models
import UserModel from '../@types/models/UserModel';
import { getModel } from './base';

export const apiGetCurrentUser = async (
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | UserModel> => {
  const model = await getModel('/user', errorEnqueueSnackbar);
  return model ? (model as UserModel) : null;
};
